/** @jsx jsx */
import { jsx, Flex, Grid, Container } from "theme-ui";

import { Link } from "gatsby";

const FooterLink = (props) => (
  <Link {...props} sx={{ color: "gray-light", textDecoration: "none" }} />
);

const Column = (props) => <Flex sx={{ flexDirection: "column" }} {...props} />;
// const Column =
const Footer = () => (
  <footer sx={{ mt: 4, bg: "gray", color: "white", height: 100 }}>
    <Container p={2}>
      <Grid gap={2} columns={[2, null, 4]}>
        <div />
        <div />
        <Column></Column>
        <Column>
          PROJECT
          <FooterLink to="/about">About</FooterLink>
          <FooterLink to="/blog">Blog</FooterLink>
        </Column>
      </Grid>
    </Container>
  </footer>
);

export default Footer;
