/** @jsx jsx */
import { jsx } from "theme-ui";

import { Link } from "gatsby";
import PropTypes from "prop-types";
import LogoImg from "../images/logo.svg";

const Header = ({ siteTitle }) => (
  <header sx={{ p: 2, borderBottom: "default" }}>
    <div sx={{ maxWidth: (theme) => theme.sizes.container, margin: "0 auto" }}>
      <Link
        to="/"
        style={{
          textDecoration: `none`,
        }}
      >
        <img src={LogoImg} alt="notegraph logo" sx={{ m: 0 }} />
      </Link>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
